@import '__importable.scss';
$cubic-transition: all cubic-bezier(0.19, 1, 0.22, 1) 200ms;
$title-header-height: 6.4rem;

.container {
	height: calc(100% - $title-header-height);
}

.title {
	height: 3rem;
	margin-bottom: 0.8rem;
	padding-inline: 0.8rem;
	@media (min-width: $media-md) {
		padding-inline: 1.6rem;
	}
	@media (min-width: $media-lg) {
		padding: 0;
	}

	> *:first-child {
		font-size: 1.6rem !important;
	}
}

.verticalTitle {
	padding: 1.6rem 0;
}

.options-container {
	@include noScrollBar;
	display: flex;
	flex-flow: column nowrap;
	gap: 0.8rem;
	height: 100%;
	background-color: $white;
	position: relative;
	padding: 0 0.8rem;

	@media screen and (min-width: $media-md) {
		padding: 0 1.6rem;
		overflow-y: auto;
		max-height: calc(100vh - $title-header-height - 10rem);
	}

	@media screen and (min-width: $media-lg) {
		padding: 0;
	}
}

@mixin hover-state($color) {
	&:not([disabled]):not(.hover-disabled) {
		&:focus-visible {
			border-color: $pink-2;
			box-shadow: 0 0 0 1px $pink-2;
		}

		@media (hover: hover) {
			@media screen and (min-width: $media-lg) {
				&:active {
					background-color: $gray-2;
					border-color: $color;
				}
			}

			&:hover:not(:active):not(:focus-visible) {
				box-shadow: 1px -1px 0px 1px $color inset;
				border-color: $color;
				cursor: pointer;

				.radio {
					&.selected .outer {
						background-color: $color;
						border-color: $color;
					}

					.outer {
						border-color: $blue-2;
					}
				}

				&[data-text-animate="true"],
				> [data-text-animate="true"] {
					transform: translate3d(2px, -2px, 0);
					transition: $cubic-transition;
				}
			}
		}
	}
}

.disabled {
	@media (hover: hover) {
		@media screen and (min-width: $media-lg) {
			&:active {
				background-color: $gray-0 !important;
				border: 1px solid $pink-2 !important;
			}
		}

		&:hover:not(:active):not(:focus-visible) {
			box-shadow: none !important;
			border-color: $gray-2 !important;
			cursor: default !important;

			&[data-text-animate="true"],
			> [data-text-animate="true"] {
				transform: none !important;
				transition: none !important;
			}
		}
	}
}

.sectionDisabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.lenses-list {
	display: flex;
	flex-flow: column;
	gap: 0.8rem;
	position: absolute;
	top: 0;
	width: calc(100% - 1.6rem);
	z-index: 1;

	&[data-readers-selected="true"] {
		top: 15.6rem;
	}

	@media screen and (min-width: $media-md) {
		width: calc(100% - 3.2rem);

		&[data-readers-selected="true"] {
			top: 18rem;
		}
	}

	@media screen and (width >= $media-lg) {
		width: 100%;
	}
}

.lenses-list-w-readers {
	top: 12rem;
}

.lens-list-item {
	display: flex;
	align-items: flex-start;
	width: 100%;
	background-color: $gray-0;
	padding: 1.6rem !important;
	border: 1px solid $gray-2;
	border-radius: $radius-4 !important;
	position: relative;
	transition: $cubic-transition;

	[data-text-animate="true"] {
		transition: $cubic-transition;
	}

	&.is-readers {
		display: flex;
		flex-flow: column nowrap;
	}

	> button[role="checkbox"] {
		margin-left: 0;
	}

	@include hover-state($gray-3);
}

.readers-variant {
	width: 100%;
	padding: 1.6rem 0.8rem 0.8rem 1.6rem;
	border-top: 1px solid $gray-2;
}

.rx-list-item {
	@extend .lens-list-item;
	padding: 0rem !important;
	border-radius: $radius-2 !important;
	cursor: pointer;

	&.is-readers {
		border-radius: $radius-2 $radius-2 0 0 !important;
	}

	&.is-selected {
		border: 1px solid $gray-3;
		border-radius: $radius-2 !important;
	}

	&.is-readers.is-selected {
		border-bottom: none;
	}
}

.rxIcon {
	height: auto;
	max-height: 8rem;
	aspect-ratio: 4/3;
	width: 7.2rem;
	flex-shrink: 0;
	padding: 0.8rem;
	position: relative;
}

.rxIconLensExpansion {
	@extend .rxIcon;
	align-self: center;
	translate: none;
	aspect-ratio: unset;
}

.rxIconHidden {
	@extend .rxIcon;
	height: 0;
}

.chevronContainer {
	flex-shrink: 0;
	display: flex;
	align-items: center;
}

.price {
	color: $gray-4;
	font-weight: 500;
}

.lensPrice {
	color: $gray-4;
	font-weight: 500;
}

.selectedText {
	position: absolute;
	top: 50%;
	translate: 0 -50%;
	right: 1.2rem;
	color: $gray-4;
	font-weight: 500;
}

.readers {
	width: calc(100% + 2px);
	left: -1px;
	position: relative;
	border: 1px solid $gray-2;
	border-radius: 0 0 $radius-2 $radius-2;
	padding: 0.8rem 0.8rem 0.8rem 1.6rem;
}

.select-button {
	width: 9.6rem;
	padding: 9px 16px 10px;
	border: 1px solid $gray-2;
	font-size: 1.4rem;
	line-height: 2.1rem;
}

.education-trigger {
	margin-left: -0.4rem;
	margin-top: 1rem;
}

.checkboxContainer {
	background-color: $gray-1 !important;
	border: 1px solid $gray-1 !important;
	align-items: flex-start;

	@media (hover: hover) {
		&:hover:not(:active):not(:focus-visible) {
			border-color: $gray-3 !important;
			box-shadow: 1px -1px 0px 1px $gray-3 inset !important;
		}
	}

	.optTitle {
		color: $gray-4;
		font-weight: 600 !important;
	}

	.optDescription {
		font-size: 1.2rem !important;
		font-weight: 500 !important;
		color: $gray-4;
	}
}

.premium-plus-tooltip {
	position: absolute;
	top: 3px;
	right: 6rem;
	line-height: 2.1rem;
}

.lens-checkbox {
	@media (hover: hover) {
		&:hover:not(:active):not(:focus-visible) {
			box-shadow: 1px -1px 0px 1px $gray-3 inset;
		}
	}
}

.extra-z-index {
	z-index: 100;
}
